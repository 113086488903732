import React, { useEffect, useRef } from 'react';

function AIContainer() {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleIframeLoad = () => {
      const iframe = iframeRef.current;
      if (iframe) {
        try {
          // Access the iframe's content window and document
          const iframeDoc = iframe.contentWindow.document;

          // Adjust the selector to target the button you want to click
          const button = iframeDoc.querySelector('action-items action-item-ai'); // Use the correct selector for the target button
          if (button) {
            button.click(); // Simulate a click
            console.log('Button clicked successfully.');
          } else {
            console.error('Button not found in iframe.');
          }
        } catch (error) {
          console.error('Error interacting with iframe:', error);
        }
      }
    };

    // Add the load event listener to the iframe
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = handleIframeLoad;
    }
  }, []);

  return (
    <div className="ai-container">
      <h1>Cisco AI</h1>
      <iframe
        ref={iframeRef}
        src="https://www.ciscolive.com/c/r/ciscolive/global/kiosk-test.html#username=testuser&password=%23Cisco123!&ipAddress=192.168.128.3&location=9&reload=true"
        width="100%"
        height="600"
        title="AI Page"
      />
    </div>
  );
}

export default AIContainer;
