import React from 'react';
import CiscoLiveEMEAAIMainContainer from './CiscoLiveEMEAAIMainContainer';

const CiscoLiveEMEAAIFullPageContainer = () => {
  return (
    <div className="full-page-container">
       <CiscoLiveEMEAAIMainContainer />
    </div>
  );
};

export default CiscoLiveEMEAAIFullPageContainer;
