import { markCurrentPosition } from "./markCurrentPosition";
import {facilityNameMapping} from './utilityMethods';
import { manageLayers } from '../main-container/markCurrentPosition'; // Import the functions

export function handleReset( long,lat, rotation, zoom,isMapCentre=true,cleanPopup=false,isMapClick=false,pitch=60,bearing=-70, whereAmIBuilding, whereAmILevel, centerLong, centerLat) {
    try{
    const pointrWeb = window.PointrWebSDK.MapWidget.getInstance();
    const uiController = pointrWeb.getUiController();
    const mapViewController = uiController.getMapViewController();
    const mapView = mapViewController.getView();

    const latlng = new window.PointrWebSDK.LatLng({ lat: lat, lng: long });
    if(!cleanPopup){
    mapView.map.flyTo({   
        center: isMapCentre?[centerLong, centerLat
        ]:[long,lat],
        zoom: zoom, pitch: pitch, bearing: bearing, speed: 0.4
      });
    }else{
      mapView.map.flyTo({
        zoom: 20, // Set this to the desired zoom level
        speed: 0.4, // Adjust speed (default is 1.2)
        curve: 1.42, // Adjust the curve (default is 1.42)
        easing: (t) => t, // Optional: customize the easing function
    });
    }
   // markCurrentPosition(window.PointrWebSDK.MapWidget.getInstance(), long, lat);
    pointrWeb.unhighlight();
    mapViewController.unhighlightAllPois();
    mapViewController.removeAllMarkers();
    mapViewController.hideRoute();
    mapViewController.removeAllCustomPointMarkersFromMap();
    mapView.setLayoutProperty("rmaps-symbol_selected_ptr", 'icon-image', 'green_marker');
    if(document.getElementById('left-container-wrapper')){
    document.getElementById('left-container-wrapper').style.display = 'block';
    }
    document.getElementById('left-container-footer').style.display = 'flex';

   // resetBoothLayerFilter(mapView);
    const navigationViewController = pointrWeb.getUiController().getNavigationViewController();
    navigationViewController.close();
    if(!isMapClick)
     markCurrentPosition(pointrWeb, long, lat, rotation,false,pitch,bearing, whereAmIBuilding, whereAmILevel,  whereAmIBuilding, whereAmILevel);
    
    //Reset Highlighted Markers
    Object.keys(facilityNameMapping).forEach(title => {
        const facilityId = facilityNameMapping[title][0];
        mapView.setLayoutProperty(`rmaps-${facilityId}-marker-highlight`, 'visibility', 'none');
        mapView.setLayoutProperty(`rmaps-${facilityId}-fill-highlight`, 'visibility', 'none');
        mapView.setLayoutProperty(`rmaps-${facilityId}-marker`, 'visibility', 'visible');
       mapView.setLayoutProperty(`rmaps-${facilityId}-covers`, 'visibility', 'visible');
      });
    } catch (error) {
      console.error("An error occurred while processing layers in handleReset:", error);
  }
}