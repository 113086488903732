import React from 'react';
import CiscoLiveEMEAMainContainer from './CiscoLiveEMEAMainContainer';

const CiscoLiveEMEAFullPageContainer = () => {
  return (
    <div className="full-page-container">
       <CiscoLiveEMEAMainContainer />
    </div>
  );
};

export default CiscoLiveEMEAFullPageContainer;
