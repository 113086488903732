import React, { useState,useEffect } from 'react';
import CiscoLiveEMEAAIFullPageContainer from './CiscoLiveEMEAAIFullPageContainer';
import AIContainer from './AIContainer';

import ciscoSpacesAIImage from '../../../../assets/images/ai-assistant-button.png';

import ciscoSpacesImage from '../../../../assets/images/spaces-button-image.png';

function CLAI2025Page() {
  const [currentView, setCurrentView] = useState('home');

  const handleNavigate = (view) => {
    setCurrentView(view);
  };

  useEffect(() => {

    const leftContainer = document.getElementById('left-container');
    if (leftContainer) {
      if (currentView === 'emea') {
        leftContainer.style.top = '20%'; // Show when EMEA container is visible
      } 
    }


    const leftContainerwrapper = document.getElementById('left-container-level-selector');
    if (leftContainerwrapper) {
      if (currentView === 'emea') {
        leftContainerwrapper.style.height = '42vh'; // Show when EMEA container is visible
      }  
    }
  }, [currentView]); 

  function setLeftContainer(){
    const leftContainer = document.getElementById('left-container');
    if (leftContainer) {
         leftContainer.style.top = '20%'; // Show when EMEA container is visible
     }


    const leftContainerwrapper = document.getElementById('left-container-level-selector');
    if (leftContainerwrapper) {
         leftContainerwrapper.style.height = '42vh'; // Show when EMEA container is visible
     }
  }
  

  return (
    <div className="clai2025-page">
      {currentView === 'home' && (
        <div className="home-page">
          <div className="logo-container"  onClick={() => {handleNavigate('emea'); setLeftContainer();}}>
          </div>
          <div className="logo-container-1" onClick={() => handleNavigate('ai')}  >

          </div>
        </div>
      )}

      {/* EMEA Container is always mounted, just toggling visibility */}
      <div className={currentView === 'emea' ? 'container-visible' : 'container-hidden'}>
      <div className="full-page-container">
            <button onClick={() => setCurrentView('home')}>Back to Home</button>
            <CiscoLiveEMEAAIFullPageContainer />
        </div>
      </div>

      {/* AI Container */}
      <div className={currentView === 'ai' ? 'container-visible' : 'container-hidden'}>
      <button onClick={() => setCurrentView('home')}>Back to Home</button>
         <AIContainer/>
      </div>
    </div>
  );
}

export default CLAI2025Page;
